import {Component, Input, OnInit, Inject} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input('icon') public icon: string = 'fa-check-circle';
  @Input('message') public message: string;
  @Input('id') public id: string;
  @Input('showLink') public showLink: boolean = true;
  @Input('link') public link: string = 'http://www.phoenix-online.de';
  @Input('linkText') public linkText: string = 'zu phoenix-online.de';

  constructor(private translate: TranslateService) {

  }

  ngOnInit() {
  }

}
